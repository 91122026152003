// - Lazysizes -
import lazySizes from 'lazysizes';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling

lazySizes.cfg.init = false; // Auto init goes wrong sometimes

import {Fancybox} from "@fancyapps/ui/src/Fancybox/Fancybox.js";
/* purgecss start ignore */
import 'swiper/css';
import "@fancyapps/ui/dist/fancybox.css";
/* purgecss end ignore */
import Swiper, {Navigation, Lazy, Pagination, Thumbs} from 'swiper';
// - Mobile nav
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

const main = async () => {
    const {default: Vue} = await import(/* webpackChunkName: "vue" */ 'vue')
    const {default: axios} = await import( /* webpackChunkName: "axios" */ 'axios')
    const {default: VueCookies} = await import( /* webpackChunkName: "vue-cookies" */ 'vue-cookies');

    Vue.use(VueCookies);

    const vm = new Vue({
        el: "#site",
        delimiters: ['${', '}'],

        components: {
            'tabs': () => import(/* webpackChunkName: "tabs" */ '../vue/Tabs.vue'),
            'tab': () => import(/* webpackChunkName: "tab" */ '../vue/Tab.vue'),
        },

        data: {
            // Navigation
            mobileNavIsOpen: false,
            langNavOpen: false,
            // hideNav: false,
            // tightNav: false,
            // fixateNav: false,
            // lastScrollPos: 0,
            searchValue: "",

            // Notifications
            // showNotificationBar: true,

            // CookieFirst
            //advertisingConsent: false, // used to show/hide AddThis share buttons
            // Modals
            modals: [],
            // Navigation panels
            openPanel: -1,
        },

        created() {

        },

        mounted() {
            // Init lazysizes
            lazySizes.init()

            // Init formie
            if (window.Formie) {
                window.Formie.initForms();

                // Loop through all forms
                document.querySelectorAll('form[data-fui-form]').forEach(form => {
                    form.addEventListener('onAfterFormieSubmit', (e) => {
                        const fileUrl = form.getAttribute('data-file-url') ?? null;
                        const modalId = form.getAttribute('data-modal-id') ?? null;
                        const payWithEmail = form.getAttribute('data-pay-with-email') ?? false;

                        if (modalId) {
                            this.closeModal(modalId);
                        }

                        if (fileUrl) {
                            window.open(fileUrl, '_blank');

                            if (payWithEmail) {
                                VueCookies.set(`payWithEmail`, 'confirmed', '7d');
                            }
                        }
                    });
                });
            }

            // Set default nav search value
            this.setNavSearchValue();

            // Init swipers
            this.initSwipers();
            // Cookie First consents
            //this.getCookieConsents();

            // Fancybox
            Fancybox.bind("[data-fancybox]", {
                Toolbar: {
                    display: ["counter", "zoom", "fullscreen", "close"]
                },
            });
            
            // Tippy
            tippy("[data-tippy-content]", {
                content: (reference) => {
                    return reference.getAttribute("data-tippy-content") ?? "";
                },
                allowHTML: true,
            });
        },

        beforeDestroy() {
        },

        methods: {
            openPanelById(id, url = null) {
                // If our current panel is already open and we provide an url, navigate to it
                if (this.openPanel === id && url) {
                    window.location.href = url;
                    
                    return;
                }
                
                this.openPanel = id;
            },
            
            isPanelOpen(id) {
                return this.openPanel === id;
            },
            
            updateProductFilters(event) {
                const form = event.target.closest('form.products-filters');
                
                if (form) {
                    form.submit();
                }
            },

            /*
             async getCookieConsents() {
             while (!window.hasOwnProperty("CookieFirst")) // define the condition as you like
             await new Promise(resolve => setTimeout(resolve, 1000));
             
             // Set consents
             this.advertisingConsent = window.CookieFirst?.consent?.advertising || false;
             },
             */

            // closeNotificationBar(slug) {
            //     VueCookies.set(`notification-${ slug }`, 'hidden', '7d');
            //     this.showNotificationBar = false;
            // },
            saveLocale(locale) {
                VueCookies.set(`locale`, locale, '365d');
            },

            initSwipers() {
                const customerBlocks = document.querySelectorAll('.block-customers');
                const textBlocks = document.querySelectorAll('.block-text');
                const heroBlocks = document.querySelectorAll('.block-hero');
                const heroTitleBlocks = document.querySelectorAll('.block-hero-title');
                const productImages = document.querySelectorAll('.product-header__images');

                customerBlocks.forEach(block => {
                    const swiperEl = block.querySelector('.swiper');

                    const swiper = new Swiper(swiperEl, {
                        modules: [Navigation, Lazy],
                        slidesPerView: 1,
                        loop: true,
                        loopedSlides: 5,
                        spaceBetween: 37,
                        preloadImages: false,
                        watchSlidesProgress: true,
                        lazy: {
                            checkInView: true,
                            loadPrevNext: true,
                            loadPrevNextAmount: 1
                        },
                        navigation: {
                            nextEl: block.querySelector('.swiper-button-next'),
                            prevEl: block.querySelector('.swiper-button-prev'),
                        },
                        breakpoints: {
                            576: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 3,
                            },
                            1024: {
                                slidesPerView: 4,
                            },
                            1440: {
                                slidesPerView: 5,
                            }
                        }
                    });
                });

                textBlocks.forEach(block => {
                    const swiperEl = block.querySelector('.swiper');

                    if (!swiperEl) {
                        return;
                    }

                    const swiper = new Swiper(swiperEl, {
                        modules: [Pagination, Lazy],
                        slidesPerView: 1,
                        loop: true,
                        preloadImages: false,
                        lazy: {
                            checkInView: true,
                            loadPrevNext: true,
                        },
                        pagination: {
                            clickable: true,
                            el: block.querySelector('.swiper-pagination'),
                            type: "bullets",
                        }
                    });
                });

                heroBlocks.forEach(block => {
                    const swiperEl = block.querySelector('.swiper');

                    const swiper = new Swiper(swiperEl, {
                        modules: [Pagination, Lazy],
                        slidesPerView: 1,
                        loop: true,
                        preloadImages: false,
                        lazy: {
                            checkInView: true,
                            loadPrevNext: true,
                        },
                        pagination: {
                            clickable: true,
                            el: block.querySelector('.swiper-pagination'),
                            type: "bullets",
                        }
                    });
                });

                heroTitleBlocks.forEach(block => {
                    const swiperEl = block.querySelector('.swiper');

                    const swiper = new Swiper(swiperEl, {
                        modules: [Pagination, Lazy],
                        slidesPerView: 1,
                        loop: true,
                        preloadImages: false,
                        lazy: {
                            checkInView: true,
                            loadPrevNext: true,
                        },
                        pagination: {
                            clickable: true,
                            el: block.querySelector('.swiper-pagination'),
                            type: "bullets",
                        }
                    });
                });

                productImages.forEach(block => {
                    const galleryEl = block.querySelector('.swiper.swiper-gallery');
                    const thumbsEl = block.querySelector('.swiper.swiper-thumbs');


                    const gallerySwiper = new Swiper(galleryEl, {
                        modules: [Pagination, Lazy, Thumbs],
                        slidesPerView: 1,
                        preloadImages: false,
                        lazy: {
                            checkInView: true,
                            loadPrevNext: true,
                        }
                    });

                    if (thumbsEl) {
                        const thumbsSwiper = new Swiper(thumbsEl, {
                            modules: [Pagination, Lazy],
                            slidesPerView: 5,
                            spaceBetween: 30,
                            preloadImages: false,
                            lazy: {
                                checkInView: true,
                                loadPrevNext: true,
                            },
                        });

                        gallerySwiper.thumbs.swiper = thumbsSwiper;
                        gallerySwiper.thumbs.init();
                    }
                });
            },

            setNavSearchValue() {
                const input = this.$refs.navSearchInput;
                const params = new URLSearchParams(window.location.search);

                if (input) {
                    this.searchValue = params.get('q');
                }
            },

            toggleMobileNav() {
                this.mobileNavIsOpen = !this.mobileNavIsOpen;
                this.mobileNavIsOpen ? disableBodyScroll(this.$refs.navMobile) : enableBodyScroll(this.$refs.navMobile);
            },

            toggleLangNav() {
                this.langNavOpen = !this.langNavOpen;
            },

            // Modals
            openModal(id) {
                if (this.modals.includes(id)) {
                    return;
                }

                this.modals.push(id);
            },
            closeModal(id) {
                const index = this.modals.indexOf(id);
                this.modals.splice(index, 1);
            },

            /**
             * Download a file (open a download form if needed)
             * @param {*} id Modal id
             * @param {*} fileUrl file url
             * @param {*} payWithEmail should the file be downloaded instantly?
             */
            download(id, fileUrl, payWithEmail) {
                const pay = VueCookies.get(`payWithEmail`) !== null ? 0 : payWithEmail;
                const modalEl = document.getElementById(`modal-${id}`);
                const form = modalEl.querySelector('form');

                if (form) {
                    if (pay) {
                        // These attributes will be used after a valid form submit
                        // Set download attribute
                        form.setAttribute('data-file-url', fileUrl);
                        // Set modal id to be closed later on
                        form.setAttribute('data-modal-id', id);
                        // Pay with email check
                        form.setAttribute('data-pay-with-email', true);

                        // Open modal
                        this.openModal(id);
                    } else {
                        // "Download" instantly
                        const link = document.createElement('a');
                        const fileName = fileUrl.split('/').slice(-1)[0];

                        link.setAttribute('download', fileName);
                        link.setAttribute('target', '_blank');
                        link.setAttribute('href', fileUrl);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        
                        form.setAttribute('data-pay-with-email', false);
                    }
                }

            }
        },
    })

    return vm
}

// Execute async function
main().then(vm => {
})

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept()
}
